<template>
  <div class="nk-header nk-header-fixed is-light">
    <div class="container-fluid">
      <div class="nk-header-wrap">
        <div class="nk-menu-trigger d-xl-none ms-n1">
          <a href="javascript:void(0);" @click="toggleMenu" class="nk-nav-toggle nk-quick-nav-icon" data-target="sidebarMenu"><em class="icon ni ni-menu"></em></a>
        </div>
        <div class="nk-header-brand d-xl-none">
          <router-link to="/" class="logo-link">
            <img class="logo-light logo-img" src="@/assets/images/aygun-logo-white.png" srcset="@/assets/images/aygun-logo-white.png" alt="logo">
            <img class="logo-dark logo-img" src="@/assets/images/aygun-logo-white.png" srcset="@/assets/images/aygun-logo-white.png" alt="logo-dark">
          </router-link>
        </div><!-- .nk-header-brand -->
<!--        <div class="nk-header-news d-none d-xl-block">-->
<!--          <div class="nk-news-list">-->
<!--            <a class="nk-news-item" href="#">-->
<!--              <div class="nk-news-icon">-->
<!--                <em class="icon ni ni-card-view"></em>-->
<!--              </div>-->
<!--              <div class="nk-news-text">-->
<!--                <p>Do you know the latest update of 2022? <span> A overview of our is now available on YouTube</span></p>-->
<!--                <em class="icon ni ni-external"></em>-->
<!--              </div>-->
<!--            </a>-->
<!--          </div>-->
<!--        </div>&lt;!&ndash; .nk-header-news &ndash;&gt;-->
        <div class="nk-header-tools">
          <ul class="nk-quick-nav">
            <li class="dropdown user-dropdown">
              <a href="javascript:void(0)" class="dropdown-toggle" @click="toggle">
                <div class="user-toggle">
                  <div class="user-avatar sm">
                    <em class="icon ni ni-user-alt"></em>
                  </div>
                  <div class="user-info d-none d-md-block">
                    <div class="user-status">Kullanıcı</div>
                    <div class="user-name dropdown-indicator">{{ _useAccount._fullName ?? _useAccount._username }}</div>
                  </div>
                </div>
              </a>
              <Menu :base-z-index="1011" ref="menu" :popup="true" :model="menuItems">
                <template #start>
                  <div class="dropdown-inner user-card-wrap bg-lighter d-md-block">
                    <div class="user-card">
                      <div class="user-avatar">
                        <span>{{ getFirstLetterFromName }}</span>
                      </div>
                      <div class="user-info">
                        <span class="lead-text">{{ _useAccount._fullName ?? _useAccount._username }}</span>
                        <span class="sub-text">{{ _useAccount._title }}</span>
                      </div>
                    </div>
                  </div>
                </template>
              </Menu>
            </li><!-- .dropdown -->
          </ul><!-- .nk-quick-nav -->
        </div><!-- .nk-header-tools -->
      </div><!-- .nk-header-wrap -->
    </div><!-- .container-fliud -->
  </div>

  <Dialog v-model:visible="showPasswordResetDialog" modal :style="{ width: '40vw' }" :breakpoints="{ '960px': '95vw', '641px': '98vw' }">
    <template #header>
      <strong><h5>Şifre Sıfırlama</h5></strong>
    </template>
    <template #default>
      <div class="row">
        <div class="col-md-12">
          <label class="form-label">Yeni Şifre</label>
          <input class="form-control" v-model="passwordFields.newPassword" type="password" placeholder="**********">
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <label class="form-label">Yeni Şifre Tekrar</label>
          <input class="form-control" v-model="passwordFields.newPasswordAgain" type="password" placeholder="**********">
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-12 text-end">
          <button @click="resetPassword" class="btn btn-primary">Kaydet</button>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script setup>
import {computed, onMounted, reactive, ref} from "vue";
import {useAccount} from "@/store/account.js";
import {useRouter} from "vue-router";
import Menu from "primevue/menu";
import Dialog from "primevue/dialog";
import {useToast} from "primevue/usetoast";

const _useAccount = useAccount()
const router = useRouter()
const toast = useToast();

const passwordFields = reactive({
  newPassword: null,
  newPasswordAgain: null
})

const menu = ref()
const showPasswordResetDialog = ref()

const menuItems = ref([
  {
    label: 'Şifre Sıfırla',
    icon: 'pi pi-fw pi-cog',
    command: () => {
      showPasswordResetDialog.value = true
    }
  },
  { separator: true },
  {
    label: 'Çıkış Yap',
    icon: 'pi pi-fw pi-sign-out',
    command: () => {
      _useAccount.$reset()
      router.push({ name : 'LoginPage' });
    }
  },
])

const toggleMenu = () => {
  const sidebar = document.querySelector('.nk-sidebar')
  sidebar.classList.toggle('nk-sidebar-active')
  const overlay = document.querySelector('.nk-sidebar-overlay')
  overlay.style.display = "block"
}

const toggle = (event) => {
  menu.value.toggle(event);
};

const getFirstLetterFromName = computed(() => {
  const arr = _useAccount._fullName.toString().split(' ')
  return arr[0].split('')[0].toUpperCase() + '' + arr[1].split('')[0].toUpperCase()
})

const resetPassword = async () => {
  if(passwordFields.newPassword !== passwordFields.newPasswordAgain){
    toast.add({ severity: 'warn', summary: 'Kaydedildi', detail: 'Şifreler uyuşmuyor. Lütfen kontrol edin.', life: 3000 });
    return;
  }

  try {
    await _useAccount.changePassword(passwordFields);
    toast.add({ severity: 'success', summary: 'Kaydedildi', detail: 'Şifreniz başaryıla değiştirilmiştir. Lütfen yeni şifreniz ile giriş yapın.', life: 6000 });
    _useAccount.$reset()
    router.push({ name : 'LoginPage' });
  }catch{
    toast.add({ severity: 'error', summary: 'Hata', detail: 'Şifreniz değiştirilirken hata meydana geldi.', life: 5000 });
  }

}
</script>

<style scoped>

</style>
