export default [
    { heading: 'Menüler' },
    {
        title: 'Satın Alma',
        icon: 'icon ni ni-layer-fill',
        permission: ['A','R'],
        permissionType: "or",
        children: [
            {
                title: 'Yeni Talep Oluştur',
                to: '/purchase/new',
                permission: ['A','R1'],
                permissionType: "or",
            },
            {
                title: 'Taleplerim',
                to: '/purchase/my-purchase',
                permission: ['A','R2'],
                permissionType: "or",
            },
            {
                title: 'Satın Alma Talep Onaylama',
                to: '/purchase/pending-purchases',
                permission: 'R4',
                permissionType: "single",
            },
            {
                title: 'Tüm Satın Alma Talepleri',
                to: '/purchase/all-purchases',
                permission: ['B','R3'],
                permissionType: "or",
            },
        ]
    },
    {
        title: 'Kargo İşlemleri',
        icon: 'icon ti ti-package',
        permission: 'SH',
        permissionType: "single",
        children: [
            {
                title: 'Gelen Kargo Listesi',
                to: '/shipment/incoming-shipments',
                permission: 'SH-2',
                permissionType: "single",
            },
        ]
    },
    {
        title: 'Araç İşlemleri',
        icon: 'icon ti ti-car',
        permission: 'VH',
        permissionType: "single",
        children: [
            {
                title: 'Araç Taleplerim',
                to: '/vehicle/my-requests',
                permission: 'VH2',
                permissionType: "single",
            },
            {
                title: 'Sorumlu Kişi Araç Talep Onayı',
                to: '/vehicle/pending-responsible-approvals',
                permission: 'VH3',
                permissionType: "single",
            },
            {
                title: 'Onay Bekleyen Araç Talepleri',
                to: '/vehicle/pending-approvals',
                permission: 'VH4',
                permissionType: "single",
            },
            {
                title: 'Araç Talep Listesi',
                to: '/vehicle/vehicle-requests-list',
                permission: 'VH5',
                permissionType: "single",
            },
            {
                title: 'Tamamlanmış Araç Talepleri',
                to: '/vehicle/completed-vehicle-requests-list',
                permission: 'VH6',
                permissionType: "single",
            },
        ]
    },
    {
        title: 'Masraf İşlemleri',
        icon: 'icon ni ni-coins',
        permission: 'ED',
        permissionType: "single",
        children: [
            {
                title: 'Masraf Beyanlarım',
                to: '/expense/my-expense-requests',
                permission: 'ED2',
                permissionType: "single",
            },
            {
                title: 'Onay Bekleyen Masraf Beyanları',
                to: '/expense/pending-expense-requests',
                permission: 'ED3',
                permissionType: "single",
            },
            {
                title: 'Masraf Beyan Listesi',
                to: '/expense/expense-request-list',
                permission: 'ED4',
                permissionType: "single",
            },
        ]
    },
    {
        title: 'Ürün İşlemler',
        icon: 'icon ni ni-briefcase',
        permission: 'P',
        permissionType: "single",
        children: [
            {
                title: 'Ürün Listesi',
                to: '/product/list'
            },
        ]
    },
    {
        title: 'Mesai İşlemleri',
        icon: 'icon ni ni-account-setting-fill',
        permission: 'OS',
        permissionType: "single",
        children: [
            {
                title: 'Fazla Mesai Ekle',
                to: '/shift/new-shift',
                permission: 'OS1',
                permissionType: "single",
            },
            {
                title: 'Fazla Mesai Taleplerim',
                to: '/shift/my-list',
                permission: 'OS9',
                permissionType: "single",
            },
            {
                title: 'Fazla Mesai Onaylama',
                to: '/shift/approve',
                permission: 'OS2',
                permissionType: "single",
            },
            {
                title: 'Fazla Mesai Listesi',
                to: '/shift/list',
                permission: 'OS3',
                permissionType: "single",
            },
            {
                title: 'Fazla Mesai Takvimi',
                to: '/shift/calendar',
                permission: 'OS8',
                permissionType: "single",
            },
            {
                title: 'Fazla Mesai Dönemleri',
                to: '/shift/shift-period',
                permission: 'OS7',
                permissionType: "single",
            },
            {
                title: 'Fazla Mesai Yetkileri',
                to: '/shift/shift-permissions',
                permission: 'OS4',
                permissionType: "single",
            },
            {
                title: 'Çalışma Bilgilerini İçeri Aktar',
                to: '/shift/import-employee-working',
                permission: 'OS6',
                permissionType: "single",
            },
            {
                title: 'Çalışan Listesi',
                to: '/shift/shift-employee-list',
                permission: 'OS5',
                permissionType: "single",
            },
            {
                title: 'Fazla Mesai Raporu',
                to: '/shift/rapor',
                permission: 'OS10',
                permissionType: "single",
            },
            {
                title: 'Devamsızlık Raporu',
                to: '/shift/normal-rapor',
                permission: 'OS11',
                permissionType: "single",
            },
            {
                title: 'Devamsızlık Raporu',
                to: '/shift/normal-rapor-2',
                permission: 'OS12',
                permissionType: "single",
            }
        ]
    },
    {
        title: 'CRM',
        icon: 'icon ni ni-user-list',
        permission: 'CRM',
        permissionType: "single",
        children: [
            {
                title: 'Müşteriler',
                to: '/crm/organization/list',
                permission: 'CRM',
                permissionType: "single",
            },
            {
                title: 'Fuarlar',
                to: '/crm/expo-list',
                permission: 'CRM',
                permissionType: "single",
            },
            {
                title: 'Fuar Görüşmeleri',
                to: '/crm/expo-records',
                permission: 'CRM',
                permissionType: "single",
            },
            {
                title: 'Analitik',
                to: '/crm/analytics',
                permission: 'CRM',
                permissionType: "single",
            },
            {
                title: 'Aksiyonlar',
                to: '/crm/task/list',
                permission: 'CRM',
                permissionType: "single",
            },
            /* {
                title: 'Görüşmeler',
                to: '/crm/action/create',
                permission: 'CRM',
                permissionType: "single",
            } */
        ]
    },
    // {
    //     title: 'Bilişim Envanter',
    //     icon: 'icon ni ni-user-list',
    //     permission: 'IT',
    //     permissionType: "single",
    //     children: [
    //         {
    //             title: 'Stok Kategorileri',
    //             to: '/it-warehouse/stock-category/list',
    //             permission: 'IT1',
    //             permissionType: "single",
    //         },
    //         {
    //             title: 'Stok Listesi',
    //             to: '/it-warehouse/stock/list',
    //             permission: 'IT2',
    //             permissionType: "single",
    //         },
    //         {
    //             title: 'Çalışan Envanter Listesi',
    //             to: '/it-warehouse/stock/employee-list',
    //             permission: 'IT3',
    //             permissionType: "single",
    //         },
    //         {
    //             title: 'Marka Listesi',
    //             to: '/it-warehouse/stock-brand/list',
    //             permission: 'IT4',
    //             permissionType: "single",
    //         }
    //     ]
    // },
    {
        title: 'Bakım İşlemleri',
        icon: 'icon ni ni-todo-fill',
        permission: 'MT',
        permissionType: "single",
        children: [
            {
                title: 'Yeni Bakım Talebi',
                to: '/maintenance/create',
                permission: 'MT1',
                permissionType: "single",
            },
            {
                title: 'Bakım Taleplerim',
                to: '/maintenance/my-list',
                permission: 'MT5',
                permissionType: "single",
            },
            {
                title: 'Bakım Şefinin İşlemini Bekleyen',
                to: '/maintenance/chief-approve',
                permission: 'MT2',
                permissionType: "single",
            },
            {
                title: 'Onay Bekleyen Bakım Talepleri',
                to: '/maintenance/approve-list',
                permission: 'MT3',
                permissionType: "single",
            },
            {
                title: 'Tüm Bakım Talepleri',
                to: '/maintenance/list',
                permission: 'MT4',
                permissionType: "single",
            }
        ]
    },
    {
        title: 'Satış Raporları',
        icon: 'icon ni ni-bar-fill-c',
        permission: 'SR',
        permissionType: "single",
        children: [
            {
                title: 'Weekly Report',
                to: '/sales-report/weekly-report',
                permission: 'SR1',
                permissionType: "single",
            },
            {
                title: 'Customer Report',
                to: '/sales-report/costumer-report',
                permission: 'SR1',
                permissionType: "single",
            }
        ]
    },
    {
        title: 'Ayarlar',
        icon: 'icon ni ni-setting',
        permission: 'S',
        permissionType: "single",
        children: [
            {
                title: 'Kullanıcı Listesi',
                to: '/settings/user',
                permission: 'S1',
                permissionType: "single",
            },
            {
                title: 'Personel Listesi',
                to: '/settings/employee',
                permission: 'S3',
                permissionType: "single",
            },
            {
                title: 'Firma Listesi',
                to: '/settings/company',
                permission: 'S2',
                permissionType: "single",
            },
            {
                title: 'Makina Listesi',
                to: '/settings/machine',
                permission: 'S4',
                permissionType: "single",
            }
        ]
    },
]

/*
{
        title: 'CRM',
        icon: 'icon ni ni-user-list',
        permission: 'CR',
        permissionType: "single",
        children: [
            {
                title: 'Fuar Listesi',
                to: '/crm/expo-list',
                permission: 'CR1',
                permissionType: "single",
            },
            {
                title: 'Fuar Müşteri Kayıtları',
                to: '/crm/expo-records',
                permission: 'CR2',
                permissionType: "single",
            }
        ]
    }
    */